import Vue from 'vue'
import App from './App.vue'
import './style/index.css'
import PrismicVue from '@prismicio/vue'
import linkResolver from './plugins/prismic/link-resolver'
import VueMeta from 'vue-meta'

// Register plugin
Vue.use(PrismicVue, {
  endpoint: 'https://alice-kentridge.prismic.io/api/v2',
  // apiOptions: { accessToken },
  linkResolver
})

Vue.use(VueMeta)

new Vue({
  render: h => h(App)
}).$mount('#app')
