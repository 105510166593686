<template lang="pug">
  section.slices-body
    //- slices...
    component(
    v-for="(slice, i) in slices",
    v-if="hasTemplate(slice, i)",
    :is="templates['slice-' + slice.slice_type]",
    :slice="slice",
    :sliceIndex="i",
    v-on="$listeners",
    :data-slice-type="slice.slice_type",
    :key="i",
    :isLast="i + 1 === slices.length")
</template>

<script>
export default {
  name: 'SlicesBody',
  props: ['slices', 'templates', 'index'],
  methods: {
    hasTemplate (slice) {
      // template exists?
      return this.templates['slice-' + slice.slice_type]
    }
  }
}
</script>

<style>
</style>
