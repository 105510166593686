<template lang="pug">
  #app.text-14.tracking-wide.font-serif
    header.text-16.lg_text-22
      //- top bar
      .fixed.top-0.left-0.w-full.bg-gray-100.z-10
        .flex.justify-between.items-center.uppercase.py-16.lg_py-14.tracking-widest
          h1.w-full.lg_w-1x2.text-center Alice Kentridge
          div.hidden.lg_block.lg_w-1x2.text-center Psychotherapist

      //- (menu - fixed bottom mobile, left corner tablet+)
      nav.fixed.z-10.bottom-0.left-0.w-full.lg_w-auto.bg-gray-100.lg_bg-transparent.uppercase
        ul.lg_block.pl-15.lg_pl-50.pt-6.pb-44.lg_pb-32.text-14.leading-22.lg_text-12.lg_leading-16(v-if="menu", :class="{'hidden': !mobileMenu}")
          .absolute.right-16.top-14.lg_hidden
            //- close menu x
            button.p-14.-m-14(@click="mobileMenu = !mobileMenu")
              svg(width="8", height="9", viewBox="0 0 8 9", fill="none", xmlns="http://www.w3.org/2000/svg")
                path(d="M7.53564 8.07104L0.464577 0.999977", stroke="currentColor")
                path(d="M7.53564 1L0.464577 8.07107", stroke="currentColor")
          //- items...
          li(v-for="item, i in menu")
            a.flex.items-center.group.tracking-widest(:href="`#` + kb(item)", @click.prevent="onMenuItemClick(item)")
              //- active dot
              svg.mr-48.group-hover_block(:class="{'hidden': i !== menuItemActiveIndex}", width="6", height="6", viewBox="0 0 6 6", fill="none", xmlns="http://www.w3.org/2000/svg")
                circle(cx="3", cy="3", r="3", fill="currentColor")
              | {{ item }}

        //- (mobile button to toggle menu)
        button.lg_hidden.w-full.p-16.leading-none.text-center.relative.tracking-widest(@click="mobileMenu = !mobileMenu")
          | PSYCHOTHERAPIST
          .absolute.top-0.right-16.h-full.flex.items-center(v-if="menu")
            svg(v-if="mobileMenu === false", width="10", height="10", viewBox="0 0 10 10", fill="none", xmlns="http://www.w3.org/2000/svg")
              path(d="M5 0V10", stroke="currentColor")
              path(d="M10 5L-1.78814e-07 5", stroke="currentColor")

    //- main content
    main(tabindex="0")

      template(v-if='doc')
        .h-50.md_hidden
        //- images section
        //- * canvas sizer via padding-bottom %
        //- * max-height ensures text below is always visible (maybe needs adjustment)
        section.relative.mt-44.mb-8.md_my-0(style="max-height:calc(100vh - 8.4rem - (2.4rem * 3))")
          .pb-ar-landing-mbl.md_pb-ar-landing-lnd

          //- images canvas
          .absolute.overlay
            //- images go here
            //- absolute positioning with % widths (w-XXXpct, md_w-XXXpct), all bottom-0, ...

            //- 1. big image background
            figure.absolute.bottom-0.right-0.w-80pct.md_w-1x2
              //- sizer via padding % from figma dims
              div(style="padding-bottom: calc(730/760 * 100%)")
              //-
              resp-img(:image="doc.hero_image_0", :bg="true")

            //- left image
            figure.absolute.bottom-0.left-0.w-1x3.md_w-1x5(style='max-height:44vh')
              //- sizer via padding % from figma dims
              div(style="padding-bottom: calc(475/322 * 100%)")
              //-
              resp-img(:image="doc.hero_image_1", :bg="true")

            //- center image
            figure.absolute.bottom-0.left-30pct.w-1x2.md_w-37pct.md_left-20pct(style="max-height:67.5vh")
              //- sizer via padding % from figma dims
              div(style="padding-bottom: calc(627/544 * 100%)")
              //-
              resp-img(:image="doc.hero_image_2", :bg="true")

            //- right image
            figure.absolute.bottom-0.right-0.w-30pct.md_right-auto.md_left-57pct.md_w-26pct(style="max-height:63vh")
              //- sizer via padding % from figma dims
              div(style="padding-bottom: calc(137/98 * 100%)")
              //-
              resp-img(:image="doc.hero_image_3", :bg="true")

        section.lg_-mt-72.max-w-screen-xl.mx-auto.px-15.flex.justify-end
          .lg_w-662.lg_mr-184
            //- slices...
            slices-body(:slices="doc.body", :templates="sliceTemplates", v-on:active="setActiveMenu($event)")

</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import SlicesBody from '@/components/SlicesBody.vue'
import RespImg from '@/components/RespImg.vue'
import SliceTextbody from '@/slices/SliceTextbody.vue'
import kb from 'lodash/kebabCase'
import throttle from 'lodash/throttle'
export default {
  name: 'App',
  metaInfo () {
    if (this.doc) {
      const title = this.$prismic.asText(this.doc.meta_title)
      const description = this.$prismic.asText(this.doc.meta_description)
      return {
        title,
        meta: [
          {
            name: 'description',
            content: description
          },
          {
            property: 'og:title',
            content: title,
            vmid: 'og:title'
          },
          {
            property: 'og:description',
            content: description,
            vmid: 'og:description'
          },
          {
            property: 'og:site_title',
            content: title,
            vmid: 'og:site_title'
          },
          {
            property: 'og:image',
            content: this.doc.meta_image?.url || '',
            vmid: 'og:image'
          },
          {
            property: 'og:type',
            content: 'website'
          }
        ]
      }
    }
  },
  components: { SlicesBody, RespImg, SliceTextbody },
  data () {
    return {
      doc: null,
      mobileMenu: false,
      menuItemActiveIndex: -1, // active section indicator
      kb,
      sliceTemplates: {
        'slice-textbody': SliceTextbody
      },
      scrollSections: null,
      afterScroll: null
    }
  },
  computed: {
    menu () {
      if (this.doc) {
        return this.doc.body
          .filter(slice => slice.primary.menu_label?.length)
          .map(slice => slice.primary.menu_label)
      }
      return null
    }
  },
  methods: {
    async getDoc () {
      try {
        this.doc = (await this.$prismic.client.getSingle('home'))?.data
      } catch (e) {
        console.error(e)
      }
    },
    onMenuItemClick (item) {
      // smooth scroll to top of element/section
      const el = this.$el.querySelector('#' + kb(item))
      el.scrollIntoView({ behavior: 'smooth' })
      // (close mobile menu)
      this.mobileMenu = false
    },
    setActiveMenu (menu) {
      this.menuItemActiveIndex = menu
    },
    onScroll: throttle(function () {
      clearTimeout(this.afterScroll)
      if (!this.scrollSections) {
        this.scrollSections = Array.from(this.$el.querySelectorAll('[data-scroll-pt]'))
      }

      // different pts between mobile/landscape
      const triggerPt = window.innerWidth > 1024 ? 150
        : 60 // mobile

      // find active section
      const activeIndex = this.scrollSections.findIndex((el, i) => {
        const box = el.getBoundingClientRect()
        return box.top <= triggerPt &&
          (box.bottom >= triggerPt || i === this.scrollSections.length - 1)
      })

      this.menuItemActiveIndex = activeIndex

      // reset after scroll
      this.afterScroll = setTimeout(() => { this.scrollSections = null }, 201)
    }, 100)
  },
  created () {
    this.getDoc()
  },
  mounted () {
    smoothscroll.polyfill()
    window.addEventListener('scroll', this.onScroll)
    // this.metaInfo()
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style>
</style>
