<template lang="pug">
  .slice-textbody.relative.pt-64.lg_pt-156(:id="menuItemId", data-scroll-pt, :class="{'min-h-screen': isLast }")
    prismic-rich-text.indent-children(ref="mainText", :field="slice.primary.text", :class="{'lg_pb-240': isLast}")

    //- read more text from slice.items (limit 1)...
    //- prismic-rich-text(v-if="hasReadMoreText", v-show="readmore", :field="slice.items[0].readmore_text")

    template(v-if="isLast")
      footer.lg_absolute.bottom-0.left-0.w-full.mt-24.lg_mt-160ff.pb-112.lg_pb-32.text-uppercase.text-9.sm_text-12.leading-15.lg_leading-20.tracking-wider
        | DESIGN BY A #[a(href="http://apracticeforeverydaylife.com", target="_blank", rel="noopener noreferrer") PRACTICE FOR EVERYDAY LIFE]<br>
        | PHOTOGRAPHY BY #[a(href="https://texbishop.co.uk/", target="_blank", rel="noopener noreferrer") TEX BISHOP]

</template>

<script>
import kb from 'lodash/kebabCase'
export default {
  name: 'SliceTextbody',
  props: {
    slice: { type: Object, default: () => ({ primary: {} }) },
    sliceIndex: { type: Number, default: -1 },
    isLast: { type: Boolean, default: false }
  },
  data () {
    return {
      readmore: false,
      readmoreBtn: null,
      indentedImg: null
    }
  },
  computed: {
    menuItemId () {
      const label = this.slice.primary.menu_label
      if (label?.length) {
        return kb(label)
      }
      return ''
    },
    hasReadMoreText () {
      return this.slice.items[0] && this.$prismic.asText(this.slice.items[0].readmore_text).length > 0
    },
    hasIndentedImg () {
      return this.slice.primary.indent_images
    }
  },
  methods: {
    appendReadMoreButton () {
      if (this.hasReadMoreText) {
        this.readmoreBtn = document.createElement('button')
        this.readmoreBtn.innerText = '(READ MORE)'
        // style
        this.readmoreBtn.classList.add('inline-block', 'tracking-wider', 'text-9', 'ml-12')
        // on click
        this.readmoreBtn.addEventListener('click', () => {
          this.readmore = true
          this.readmoreBtn.classList.add('hidden')
        })
        // append!
        this.$refs.mainText.lastChild.append(this.readmoreBtn) // append to last <p>
      }
    },
    indentImg () {
      if (this.hasIndentedImg) {
        this.$refs.mainText.classList.add('indent-img')
      }
    }
  },
  mounted () {
    this.appendReadMoreButton()
    this.indentImg()
  }
}
</script>

<style lang="postcss">

.slice-textbody {
  /* margins */
  & h2:nth-child(n + 2) {
    @apply mt-19; /* 1 lh */

    @screen lg{
      @apply mt-26;
    }
  }

  /* images */
  & img{
    @apply my-24 max-w-16-3;
    /* using md here cause I thought img looked too small while on md */
    @screen md {
      @apply my-36 max-w-30;
    }
  }
}

.indent-children {
  & h2, & p {
    text-indent: 5rem;
    @screen lg {
      text-indent: 6.5rem; /* APFEL edit: to half size from 13rem */
    }
  }

  & h2 + p{
    text-indent:0;
  }
}

/* indented imgs */
.indent-img {
  & img {
    margin-left: 5rem;
    @screen lg {
      margin-left: 6.5rem;
    }
  }
}

/* type */
.slice-textbody {
  @apply text-14 tracking-wide leading-19;

  & h2 {
    @apply text-9 uppercase tracking-widest;
  }

  @screen sm {
    @apply leading-26;
    & p { @apply text-22 }
    & h2 { @apply text-12  }
  }
}

/* "read more" styling via <b></b> */
.slice-textbody{
  & strong, & b{
    @apply text-9 not-italic font-normal tracking-widest uppercase inline-block;
    /* margin-left: 1.5em; */
    @screen lg{
      @apply text-12
    }
  }
}
</style>
